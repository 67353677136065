import { mapJobTagsToBadges } from '@seek/seek-jobs-analytics';
import { useCallback, useEffect, useState } from 'react';

import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import {
  getIsAddSalaryPrefNudgeShown,
  getIsSalaryMatch,
} from 'src/hooks/trackJobDetailsLoaded';
import { useSolHash } from 'src/hooks/useSolHash';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import type {
  JobDetails,
  PersonalisedJobDetails,
} from 'src/store/jobdetails/types';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectSolMetadata,
  selectSearchQuery,
} from 'src/store/selectors';
import { getViewJobOriginRef } from 'src/utils/eventCapture/eventCaptureUtils';

interface Options {
  jobDetails: JobDetails;
  isHirerVerified: boolean;
  jobDetailsPersonalised?: PersonalisedJobDetails;
  solMetadataReference?: string;
  isMatchedSkillsWidgetShown?: boolean;
  isMatchedSkillsNudgeShown?: boolean;
}

type UseApplyTracking = (options: Options) => [string, () => void];

export const useApplyTracking: UseApplyTracking = ({
  jobDetails,
  jobDetailsPersonalised,
  solMetadataReference,
  isHirerVerified,
  isMatchedSkillsWidgetShown,
  isMatchedSkillsNudgeShown,
}) => {
  const analyticsFacade = useAnalyticsFacade();
  const isAuthenticated = useSelector(selectAuthenticated);
  const solMetadata = useSelector(selectSolMetadata);
  const searchParams = useSelector(selectSearchQuery);
  const { id, isLinkOut, salary } = jobDetails?.job || {};
  const [initialUrlStr, solMeta, getTrackingInfo] = useSolHash({
    id,
    isLinkOut,
    solMetadataReference,
  });

  const salaryMatch = jobDetailsPersonalised?.salaryMatch;
  const isSalaryMatch = getIsSalaryMatch(salaryMatch);
  const searchRemoteOptions = searchParams?.workarrangement;
  const isAddSalaryPrefNudgeShown = getIsAddSalaryPrefNudgeShown({
    isAuthenticated,
    isSalaryLabel: Boolean(salary?.label),
    salaryMatch,
  });

  const selectedJob = useSplitViewContext()?.selectedJob;

  /**
   * Only applicable if user is in splitView since currently
   * there is no way to track jobTags if user is in standalone
   * job details page.
   */
  const jobBadges = mapJobTagsToBadges(selectedJob?.tags);

  const [urlStr, setUrlStr] = useState(initialUrlStr);
  useEffect(() => {
    const [url] = getTrackingInfo();
    setUrlStr(url);
  }, [getTrackingInfo, setUrlStr]);

  const experimentFlightId = solMetadata?.tags?.mordor__flights ?? '';

  const onApplyClick = useCallback(() => {
    /**
     * If there is no ref query in the URL and the user is
     * in splitView then it is garuanteed the user clicked
     * apply from split view SERP.
     */
    const viewJobOriginRef = getViewJobOriginRef();
    const ref = !viewJobOriginRef ? 'search-split' : viewJobOriginRef;

    analyticsFacade.jobApplyClicked(
      {
        experimentFlightId,
        href: urlStr,
        jobBadges,
        jobViewOriginQuery: ref,
        isHirerVerified,
        isMatchedSkillsWidgetShown,
        isMatchedSkillsNudgeShown,
      },
      {
        isAddSalaryPrefNudgeShown,
        isSalaryMatch,
        searchRemoteOptions,
        jobBadges,
        jobId: id,
        linkout: isLinkOut,
        solMetadata: solMeta,
        thirdPartyLink: isLinkOut,
        isMatchedSkillsWidgetShown,
        isMatchedSkillsNudgeShown,
      },
    );
  }, [
    analyticsFacade,
    experimentFlightId,
    urlStr,
    jobBadges,
    isHirerVerified,
    isMatchedSkillsWidgetShown,
    isMatchedSkillsNudgeShown,
    isAddSalaryPrefNudgeShown,
    isSalaryMatch,
    searchRemoteOptions,
    id,
    isLinkOut,
    solMeta,
  ]);

  return [urlStr, onApplyClick];
};
