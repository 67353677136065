import debounce from 'lodash/debounce';
import { memo, useEffect, useState } from 'react';
import hash from 'string-hash';

import type { TargetingData } from './targeting/search';
import type { TGooglePublisherTag } from './types';
import { loadScript } from './utils';

interface ManageAdProps {
  gpt: TGooglePublisherTag;
  tagName: string;
}

interface InitAdProps
  extends Omit<GooglePublisherTagProps, 'targetingData'>,
    ManageAdProps {}
const initAd = ({ gpt, tagName, tagId, width, height }: InitAdProps) => {
  gpt.cmd.push(() => {
    gpt.defineSlot(tagId, [width, height], tagName)?.addService(gpt.pubads());
    gpt.display(tagName);
  });
};

interface RefreshAdProps
  extends Pick<GooglePublisherTagProps, 'targetingData'>,
    ManageAdProps {}
const refreshAd = ({ gpt, tagName, targetingData }: RefreshAdProps) => {
  gpt.cmd.push(() => {
    const slot = gpt
      .pubads()
      .getSlots()
      .find((s) => s.getSlotElementId() === tagName);

    if (slot) {
      slot.clearTargeting();

      if (targetingData && Array.isArray(targetingData)) {
        targetingData.map(({ key, value }) => {
          if (key === 'experiment') {
            gpt.pubads().setTargeting(key, value);
          }
          slot.setTargeting(key, value);
        });
      }
      gpt.pubads().refresh([slot]);
    }
  });
};
const DEFAULT_DEBOUNCE = 500;
interface GooglePublisherTagProps {
  tagId: string;
  width: number;
  height: number;
  targetingData: TargetingData;
}
const GooglePublisherTagView: React.FC<GooglePublisherTagProps> = (props) => {
  const { height, tagId, targetingData, width } = props;
  const [gpt, setGpt] = useState<TGooglePublisherTag | null>(null);
  const tagName = hash(tagId).toString();

  useEffect(() => {
    const loadAndInit = async () => {
      const tag = await loadScript();
      setGpt(tag);
      initAd({
        gpt: tag,
        tagName,
        tagId,
        width,
        height,
      });
    };
    loadAndInit();
  }, [height, tagId, tagName, width]);

  useEffect(() => {
    const debounceRefreshAd = debounce(refreshAd, DEFAULT_DEBOUNCE);
    if (gpt) {
      debounceRefreshAd({
        gpt,
        tagName,
        targetingData,
      });
    }

    return () => {
      debounceRefreshAd.cancel();
    };
  }, [gpt, tagId, tagName, targetingData]);

  return <div data-automation={`ad-${tagId}`} id={tagName} />;
};

export const GooglePublisherTag = memo(GooglePublisherTagView);
