import type { TGooglePublisherTag } from './types';

const initGpt = () => {
  window.googletag.cmd.push(() => {
    googletag.pubads().collapseEmptyDivs();
    googletag.pubads().disableInitialLoad();
    googletag.pubads().setTargeting('allowtransparency', '');
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent: 200,
      renderMarginPercent: 100,
      mobileScaling: 2,
    });
    googletag.enableServices();
  });
};

// Using Singleton pattern here to ensure that the script is only loaded once for all ads
let gptScript: Promise<TGooglePublisherTag> | null = null;
export const loadScript = (): Promise<TGooglePublisherTag> => {
  if (!gptScript) {
    gptScript = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.src = 'https://www.googletagservices.com/tag/js/gpt.js';
      script.onload = () => {
        initGpt();
        resolve(window.googletag);
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
  return gptScript;
};
